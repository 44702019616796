import React, { FC, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";
import { transparentize } from "polished";
import { Link } from "gatsby";

import { colors, borders } from "../global-style";
import { WpHtmlContent } from "./wp-html-content";

interface IProps {
  maxWidth?: string;
  contentText?: string;
  linkText?: any;
  linkUrl?: any;
  linkTarget?: any;
}

export const BackgroundBlockAbs: FC<IProps> = props => {
  const { maxWidth, contentText, linkText }: IProps = props;

  const textElement = useRef<any>(null);
  const textContainer = useRef<any>(null);

  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        const cWidth = textElement.current
          ? textElement.current.getBoundingClientRect().width
          : 0;

        const cRight = textElement.current
          ? textElement.current.getBoundingClientRect().right
          : 0;

        const cHeight = textElement.current
          ? textElement.current.getBoundingClientRect().height
          : 0;

        const textContainerTop = textContainer.current
          ? textContainer.current.offsetTop
          : 0;

        return (
          <>
            <div
              ref={textContainer}
              style={{
                paddingTop: "5rem",
                paddingBottom: "5rem",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: textContainerTop,
                  maxWidth: maxWidth ? maxWidth : "inherit",
                  height: `calc(${cHeight}px + 10rem)`,
                  width: `calc(${cRight}px + 25rem)`,
                  borderRadius: "0 0 3rem 0",
                  zIndex: -1,
                }}
                css={`
                  background-color: ${transparentize(0.23, colors.aluminum)};

                  @media (max-width: 1024px) {
                    width: 100vw !important;
                    border-radius: 0px 0px 0px 0px !important;
                  }

                  @media (max-width: 1024px) {
                    background-color: ${transparentize(0, colors.aluminum)};
                  }
                `}
              />
              <div
                style={{
                  margin: "0 1.5rem",
                  display: "flex",
                }}
              >
                <div
                  ref={textElement}
                  style={{
                    border: borders.green,
                    flexBasis: "31rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flexBasis: "31rem", margin: "0.5rem" }}>
                    <WpHtmlContent
                      style={{
                        marginBottom: "3rem",
                      }}
                      htmlContent={contentText}
                    />
                    {linkText ? (
                      <a
                        style={{
                          padding: "0.5rem 2rem",
                          backgroundColor: colors.rhino,
                          color: colors.superiorWhite,
                        }}
                        href={props.linkUrl}
                        target={props.linkTarget}
                      >
                        {linkText}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </ReactResizeDetector>
  );
};
